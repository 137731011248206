import React from "react";
import AntdTable from "../../../components/common/AntdTable";
import moment from "moment";
import { Tabs } from "antd";

const CashbookTable = ({ cashbookData = {}, title = "" }) => {
  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "DETAILS",
      dataIndex: "details",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
    },
    {
      title: "ACCOUNT",
      dataIndex: "account",
    },
    {
      title: "PROJECT",
      dataIndex: "project",
    },
    {
      title: "PV/IR-NO",
      dataIndex: "receiptId",
    },
    {
      title: "INFLOW",
      dataIndex: "income",
      align: "center",
      render: (record) => (record ? `UGX ${record?.toLocaleString()}` : "--"),
    },
    {
      title: "OUTFLOW",
      dataIndex: "expense",
      align: "center",
      render: (record) => (record ? `UGX ${record?.toLocaleString()}` : "--"),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      align: "center",
      render: (record) => (record ? `UGX ${record?.toLocaleString()}` : "--"),
    },
  ];

  return (
    <div>
      <Tabs
        tabBarStyle={{
          marginBottom: 10,
        }}
        items={[
          {
            label: "ALL TRANSACTION",
            key: "all-transactions",
            children: (
              <AntdTable
                title={() => title}
                columns={columns}
                pagination={false}
                data={cashbookData.cashbook}
              />
            ),
          },
          {
            label: "BANK TRANSACTION",
            key: "bank-transactions",
            children: (
              <AntdTable
                title={() => title}
                columns={columns}
                pagination={false}
                data={cashbookData.bankTransactionsCashbook}
              />
            ),
          },
          {
            label: "CASH TRANSACTION",
            key: "cash-transactions",
            children: (
              <AntdTable
                title={() => title}
                columns={columns}
                pagination={false}
                data={cashbookData.cashTransactionsCashbook}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CashbookTable;
