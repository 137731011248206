import { Alert, Col, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import AntdTable from "../../../common/AntdTable";
import ActionPointsForm from "./ActionPointsForm";
import { searchTable } from "../../../../config/constants";
import TableTitle from "../../../common/TableTitle";
import { useReportingTemplateStore } from "../../../../config/stores";
import TableButtonActions from "../../../common/TableButtonActions";
import { useSelector } from "react-redux";

const ActionPoints = ({ reportingTemplate = {} }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [filterTable, setFilterTableNull] = useState(null);

  const { advanceAccountabilityFormsDetailsLoading } = useSelector(
    (state) => state.advanceAccountabilityFormsState
  );

  const reportingTemplateStore = useReportingTemplateStore();

  const search = (value) => {
    const filterTable = searchTable(reportingTemplate.actionPoints, value);
    setFilterTableNull(filterTable);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const updateRecord = (record) => {
    reportingTemplateStore.setCreatingActionPoint(false, record);
  };

  const confirmDelete = () => {
    reportingTemplateStore.deleteReportingTemplate(deleteId);
  };
  return (
    <div>
      {isEmpty(reportingTemplate) ? (
        <Alert
          message="Please create a reporting template to create action points"
          showIcon
          className="m-2"
        />
      ) : (
        <>
          <Row>
            <Col span={8}>
              <ActionPointsForm reportingTemplate={reportingTemplate} />
            </Col>
            <Col span={16}>
              <AntdTable
                title={() => (
                  <TableTitle
                    setFilterTableNull={setFilterTableNull}
                    openAddModal={false}
                    refreshTable={null}
                    search={search}
                    exportRecords={null}
                  />
                )}
                loading={advanceAccountabilityFormsDetailsLoading}
                data={
                  filterTable === null
                    ? reportingTemplate.actionPoints || []
                    : filterTable
                }
                columns={[
                  {
                    title: "ACTION POINT",
                    dataIndex: "action_point",
                  },
                  {
                    title: "PERSON RESPONSIBLE",
                    dataIndex: "person_responsible",
                  },
                  {
                    title: "TIMELINE",
                    dataIndex: "timeline",
                  },
                  {
                    title: "STATUS",
                    dataIndex: "status",
                  },
                  {
                    title: "ACTION",
                    render: (record) => {
                      return (
                        <TableButtonActions
                          record={record}
                          confirmDelete={confirmDelete}
                          deleteLoading={
                            reportingTemplateStore.deletingActionPoint
                          }
                          updateRecord={updateRecord}
                          deleteId={deleteId}
                          deleteRecord={deleteRecord}
                        />
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ActionPoints;
