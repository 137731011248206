import React from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import { isEmpty } from "lodash";
import { Alert, Card, Col, Popconfirm, Row, Space } from "antd";
import AppLoader from "../../../../components/common/AppLoader";
import dayjs from "dayjs";
import AppButton from "../../../../components/common/AppButton";
import {
  AiFillCloseCircle,
  AiFillSave,
  AiOutlineArrowUp,
  AiOutlineReload,
} from "react-icons/ai";
import TimeSheetActivitiesTable from "../TimeSheetActivities/TimeSheetActivitiesTable";
import AntdTable from "../../../../components/common/AntdTable";
import TimeSheetActivitiesForm from "./TimeSheetActivitiesForm";
import TimeSheetDayActivities from "../TimeSheetActivities/DayActivities";

const TimeSheetDetails = () => {
  const timeSheetStore = useTimeSheetStore();

  const { timeSheetDetails, gettingTimeSheetDetails } = timeSheetStore;
  const month = dayjs(`${timeSheetDetails?.month}`).format("MMMM");

  const closeTimeSheet = () =>
    timeSheetStore.setShowViewTimeSheetDetails(false);
  const firstDay = dayjs(`${timeSheetDetails?.month}`)
    .startOf("month")
    .format("DD");
  const lastDay = dayjs(`${timeSheetDetails?.month}`)
    .endOf("month")
    .format("DD");

  const approvedAt = dayjs(timeSheetDetails.approved_at);

  return (
    <div>
      {!isEmpty(timeSheetStore.getTimeSheetDetailsError) && (
        <Alert
          message={timeSheetStore.getTimeSheetDetailsError?.message}
          type="error"
          showIcon
          className="my-2"
          action={
            <Space>
              <AppButton
                text="Close"
                type="default"
                iconBefore={<AiFillCloseCircle />}
                danger
                onClick={closeTimeSheet}
              />
            </Space>
          }
        />
      )}
      {isEmpty(timeSheetDetails) &&
      !gettingTimeSheetDetails &&
      isEmpty(timeSheetStore.getTimeSheetDetailsError) ? (
        <AppLoader message="GETTING TIME SHEET DETAILS" />
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={`${month} ${timeSheetDetails.year} TIME SHEET`.toUpperCase()}
              extra={
                <Space>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      danger: true,
                    }}
                    okButtonProps={{
                      color: "green",
                    }}
                    onConfirm={() =>
                      timeSheetStore.approveTimeSheet(timeSheetDetails.id)
                    }
                    title="Are you sure you want to approve this time sheet"
                  >
                    {timeSheetDetails.status !== "approved" &&
                      timeSheetDetails.status !== "pending" && (
                        <AppButton
                          text="Approve"
                          type="submit"
                          loadingText="Approving..."
                          iconBefore={<AiFillSave />}
                          loading={timeSheetStore.approvingTimeSheet}
                        />
                      )}
                  </Popconfirm>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    cancelButtonProps={{
                      danger: true,
                    }}
                    okButtonProps={{
                      color: "green",
                    }}
                    onConfirm={() =>
                      timeSheetStore.submitTimeSheet(timeSheetDetails.id)
                    }
                    title="Are you sure you want to approve this time sheet"
                  >
                    {timeSheetDetails.status !== "approved" &&
                      timeSheetDetails.status === "pending" && (
                        <AppButton
                          text="Submit"
                          type="submit"
                          loadingText="Submitting..."
                          iconBefore={<AiOutlineArrowUp />}
                          loading={timeSheetStore.submittingTimesSheet}
                        />
                      )}
                  </Popconfirm>
                  <AppButton
                    text="Reload"
                    type="default"
                    iconBefore={<AiOutlineReload />}
                    onClick={() =>
                      timeSheetStore.getTimeSheetDetails(timeSheetDetails.id)
                    }
                  />
                  <AppButton
                    text="Close"
                    type="default"
                    iconBefore={<AiFillCloseCircle />}
                    danger
                    onClick={closeTimeSheet}
                  />
                </Space>
              }
            >
              <AntdTable
                title={() => (
                  <span className="fw-bold">AMANI INITIATIVE TIME SHEET</span>
                )}
                className="mb-1"
                showHeader={false}
                columns={[
                  { dataIndex: "column" },
                  {
                    dataIndex: "value",
                    render: (value) => (
                      <span className="text-uppercase fw-bold">{value}</span>
                    ),
                  },
                ]}
                data={[
                  {
                    column: "PERIOD",
                    value: `${firstDay}-${lastDay} ${month} ${timeSheetDetails.year} `,
                  },
                  {
                    column: "NAME OF STAFF",
                    value: `${timeSheetDetails?.user?.first_name || ""} ${
                      timeSheetDetails?.user?.other_names || ""
                    }`,
                  },
                  {
                    column: "POSITION",
                    value: timeSheetDetails?.role?.role || "",
                  },
                  {
                    column: "STATUS",
                    value: timeSheetDetails?.status || "",
                  },
                  {
                    column: "APPROVED AT",
                    value: approvedAt.isValid()
                      ? approvedAt.format("DD/MM/YYYY HH:mm")
                      : "NOT APPROVED",
                  },
                  {
                    column: "APPROVED BY",
                    value: `${
                      timeSheetDetails?.approved_by?.first_name || "NOT"
                    } ${
                      timeSheetDetails?.approved_by?.other_names || " APPROVED"
                    }`,
                  },
                ]}
              />
              <TimeSheetActivitiesForm />
              <TimeSheetActivitiesTable />
              <TimeSheetDayActivities />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TimeSheetDetails;
