import { Modal, Form, Button, message, Alert, Input } from "antd";
import { map } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AppInput from "../../../common/AppInput";

const AddPaymentVoucher = ({ visible, handleCloseModal, handleAddRecord }) => {
  const {
    addPaymentVouchersLoading,
    addPaymentVouchersSuccess,
    addPaymentVouchersError,
    addPaymentVouchersMessage,
    paymentVouchersMetaDataLoading,
    paymentVouchersMetaData,
    paymentVouchers,
  } = useSelector((state) => state.paymentVouchersState);
  const [form] = Form.useForm();
  const closeModal = () => handleCloseModal(false);
  const onFinish = (values) => {
    values.created_at = new Date();
    handleAddRecord(values, paymentVouchers);
  };

  useEffect(() => {
    if (addPaymentVouchersSuccess && addPaymentVouchersMessage) {
      message.success(addPaymentVouchersMessage);
      form.resetFields();
    }
  }, [addPaymentVouchersMessage, addPaymentVouchersSuccess, form]);
  return (
    <div>
      <Modal
        onCancel={closeModal}
        open={visible}
        footer={null}
        title="CREATE FUNDS ADVANCE/WITHDRAW FORM"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {!addPaymentVouchersSuccess && addPaymentVouchersError && (
            <Alert type="error" showIcon message={addPaymentVouchersError} />
          )}
          <AppInput
            rules={[
              { required: true, message: "Please select a requisition form" },
            ]}
            label="Requisition Form"
            name="requisition_memo"
            type="select"
            options={map(paymentVouchersMetaData?.requisitionMemos, (memo) => ({
              value: memo.id,
              label: `${memo.memo_number} - (${map(
                memo?.activities,
                "activity"
              ).join(", ")})`,
            }))}
            inputAttributes={{
              loading: paymentVouchersMetaDataLoading,
            }}
          />
          <AppInput
            rules={[
              {
                required: true,
                message: "Please select a cash or bank account",
              },
            ]}
            label="Cash/Bank Account"
            name="account_id"
            type="select"
            options={map(paymentVouchersMetaData?.accounts, (account) => ({
              value: account.id,
              label: account.name,
            }))}
            inputAttributes={{
              loading: paymentVouchersMetaDataLoading,
            }}
          />
          <AppInput
            name="category"
            type="select"
            label="Category"
            options={[
              { label: "Expense", value: "Expense" },
              { label: "Asset", value: "Asset" },
            ]}
            rules={[
              {
                required: true,
              },
            ]}
          />
          <Form.Item
            rules={[{ required: true, message: "Please add a cheque number" }]}
            name="cheque_number"
            label="Cheque Number"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={addPaymentVouchersLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddPaymentVoucher;
