import React, { useEffect } from "react";
import AntdModal from "../../../components/common/AntdModal";
import { useChildIncidentProtectionFormStore } from "../../../config/stores";
import { Form, message } from "antd";
import AppInput from "../../../components/common/AppInput";
import { map } from "lodash";
import AppButton from "../../../components/common/AppButton";

const AssignUser = () => {
  const incidentFormStore = useChildIncidentProtectionFormStore();
  const {
    showChildProtectionFormStatus,
    metaData,
    assigningChildIncidentReportingForm,
    assignUserToForm,
    childProtectionFormDetails,
  } = incidentFormStore;

  const closeModal = () => {
    incidentFormStore.setFormAction();
  };
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ status: "REFERRED" });
  }, [showChildProtectionFormStatus, form]);

  const onSave = (values) => {
    assignUserToForm(childProtectionFormDetails.id, values);
  };
  return (
    <div>
      <AntdModal
        open={showChildProtectionFormStatus}
        title="ASSIGN FORM TO USER"
        closeModal={closeModal}
      >
        <Form onFinish={onSave} layout="vertical" form={form}>
          <AppInput
            name="status"
            label="Status"
            inputAttributes={{
              readOnly: true,
            }}
          />
          <AppInput
            name="assigned_to_id"
            label="Assign to"
            type="select"
            rules={[{ required: true, message: "Please selected an assignee" }]}
            options={map(metaData.users, (user) => ({
              label: `${user.first_name} ${user.other_names}`,
              value: user.id,
            }))}
          />
          <AppButton
            text="Assign User"
            loadingText="Assigning..."
            loading={assigningChildIncidentReportingForm}
          />
        </Form>
      </AntdModal>
    </div>
  );
};

export default AssignUser;
