import React, { useEffect, useState } from "react";
import { useCashbookStore } from "../../../config/stores";
import { Alert, Card, Space } from "antd";
import CashbookContext from "./CashbookContext";
import CashbookTable from "./CashbookTable";
import { find, isEmpty } from "lodash";
import AppButton from "../../../components/common/AppButton";
import { AiOutlineFileExcel, AiOutlineReload } from "react-icons/ai";

const GeneralCashbook = () => {
  const cashbookStore = useCashbookStore();
  const [cashbook, setCashbook] = useState([]);

  useEffect(() => {
    if (!isEmpty(cashbookStore.cashbookContext)) {
      const cashbookData = find(cashbookStore.cashbook, (records) => {
        return (
          records?.startDate === cashbookStore.cashbookContext?.startDate &&
          records?.endDate === cashbookStore.cashbookContext?.endDate
        );
      });
      setCashbook(cashbookData?.data);
    }
  }, [cashbookStore.cashbook, cashbookStore.cashbookContext]);

  const reloadCashbook = () => {
    if (!isEmpty(cashbookStore.cashbookContext)) {
      cashbookStore.getCashbook(cashbookStore.cashbookContext);
    }
  };

  const downloadCashbook = () => {
    if (!isEmpty(cashbookStore.cashbookContext)) {
      cashbookStore.downloadCashbook(
        cashbookStore.cashbookContext,
        `AMANI INITIATIVE CASHBOOK FOR PERIOD ${cashbookStore.cashbookContext.startDate} TO ${cashbookStore.cashbookContext.endDate}.xlsx`
      );
    }
  };

  return (
    <div>
      <Card
        bordered={false}
        title={<CashbookContext />}
        extra={
          <>
            {!isEmpty(cashbookStore.cashbookContext) && (
              <Space>
                <AppButton
                  text="RELOAD"
                  loadingText="RELOADING..."
                  loading={cashbookStore.gettingCashbook}
                  iconBefore={<AiOutlineReload />}
                  onClick={reloadCashbook}
                />
                <AppButton
                  text="DOWNLOAD"
                  danger
                  type="dashed"
                  loadingText="RELOADING..."
                  iconBefore={<AiOutlineFileExcel />}
                  loading={cashbookStore.downloadingCashbook}
                  onClick={downloadCashbook}
                />
              </Space>
            )}
          </>
        }
      >
        {isEmpty(cashbookStore.cashbookContext) ? (
          <Alert
            type="info"
            showIcon
            message="PLEASE SELECT A START DATE AND END DATE TO VIEW THE CASHBOOK"
          />
        ) : (
          <CashbookTable cashbookData={cashbook} />
        )}
      </Card>
    </div>
  );
};

export default GeneralCashbook;
