import { Button, message, Popover, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";
import appConfig from "../../../../config/config.json";
import {
  AiOutlineCheckCircle,
  AiOutlineException,
  AiOutlineExclamationCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { map, uniqBy } from "lodash";

const RequisitionMemoTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    requisitionMemos: data,
    requisitionMemosLoading: loading,
    requisitionMemosSuccess: success,
    requisitionMemosError: error,
    deleteRequisitionMemosLoading: deleteLoading,
    deleteRequisitionMemosSuccess: deleteSuccess,
    deleteRequisitionMemosError: deleteError,
    deleteRequisitionMemosMessage: deleteMessage,
  } = useSelector((state) => state.requisitionMemoState);
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);

  const budgetLines = map(uniqBy(data, "budget_line"), (budgetLine) => ({
    text: budgetLine.budgetLine,
    value: budgetLine.budget_line,
  }));
  const types = map(uniqBy(data, "type"), (memo) => ({
    text: memo.type,
    value: memo.type,
  }));
  const projects = map(uniqBy(data, "project"), (memo) => ({
    text: memo.project,
    value: memo.project,
  }));
  const statuses = [
    {
      text: "Pending",
      value: "pending",
    },
    {
      text: "Checked",
      value: "checked",
    },
    {
      text: "Approved",
      value: "approved",
    },
    {
      text: "Rejected",
      value: "rejected",
    },
  ];

  const columns = [
    {
      title: "Requisition Form",
      key: "memo_number",
      render: (text) => (
        <div>
          <span className="d-md-none">Requisition Form:</span>{" "}
          {text.memo_number} <br />
          <div className="d-md-none">
            Type: {text.type} <br />
            Budget Line: {text.budgetLine} <br />
            Project: {text.project} <br />
            Status:{" "}
            {text.status === appConfig.status.approved && (
              <Tag icon={<AiOutlineCheckCircle />} color="success">
                {text.status}
              </Tag>
            )}
            {text.status === appConfig.status.checked && (
              <Tag icon={<AiOutlineMinusCircle />} color="processing">
                {text.status}
              </Tag>
            )}
            {text.status === appConfig.status.created && (
              <Tag icon={<AiOutlineExclamationCircle />} color="warning">
                Pending
              </Tag>
            )}
            {text.status === appConfig.status.rejected && (
              <Tag icon={<AiOutlineException />} color="error">
                Rejected
              </Tag>
            )}{" "}
            <br />
            <Popover
              className=""
              trigger="click"
              placement="bottom"
              content={() =>
                tableButtonActions(text, {
                  viewDetails,
                  updateRecord,
                  confirmDelete,
                  deleteRecord,
                  deleteLoading,
                  deleteId,
                })
              }
            >
              Actions: <Button type="link">Actions</Button>
            </Popover>
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      responsive: ["md"],
      filters: types,
      filterSearch: true,
      onFilter: (value, record) => record?.type?.startsWith(value),
    },
    {
      title: "Budget Line",
      dataIndex: "budgetLine",
      key: "budgetLine",
      responsive: ["md"],
      filters: budgetLines,
      filterSearch: true,
      onFilter: (value, record) => record?.budget_line?.startsWith(value),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      responsive: ["md"],
      filters: projects,
      filterSearch: true,
      onFilter: (value, record) => record?.project?.startsWith(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["md"],
      filters: statuses,
      filterSearch: true,
      onFilter: (value, record) => record?.status?.startsWith(value),
      render: (text) => {
        if (text === appConfig.status.approved)
          return (
            <Tag icon={<AiOutlineCheckCircle />} color="success">
              {text}
            </Tag>
          );
        else if (text === appConfig.status.checked)
          return (
            <Tag icon={<AiOutlineMinusCircle />} color="processing">
              {text}
            </Tag>
          );
        else if (text === appConfig.status.rejected)
          return (
            <Tag icon={<AiOutlineException />} color="error">
              {text}
            </Tag>
          );
        else
          return (
            <Tag icon={<AiOutlineExclamationCircle />} color="warning">
              Pending
            </Tag>
          );
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
      responsive: ["md"],
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const requisitionMemosArray = data.filter((e) => e.id !== deleteId);
    handleDelete(deleteId, requisitionMemosArray);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = () => {
    message.info(
      "You can only edit requisition forms of projects you've been assigned to"
    );
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    message.info(
      "You can add requisition form in the projects you've been assigned to "
    );
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["Form Number", "Project", "Status"];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [
        record.memo_number,
        record.project,
        record.status.toUpperCase(),
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Requisition Forms - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Requisition Forms.pdf"
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "requisitionMemos") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  return (
    <div>
      <Table
        pagination={{ total: data.length, showSizeChanger: true, showTotal }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
      />
    </div>
  );
};

export default RequisitionMemoTable;
