import React from "react";
import AppButton from "./AppButton";
import { AiOutlineCloseCircle, AiOutlineUpCircle } from "react-icons/ai";

const RecordStatusButtons = ({
  data = {},
  requiresSubmitted = false,
  hasRejection = false,
  storeActions = {
    submit: () => {},
    submitting: false,
    check: () => {},
    checking: false,
    approve: () => {},
    approving: false,
    rejecting: false,
    reject: () => {},
    undoApprove: () => {},
    undoingApproval: false,
  },
}) => {
  const isSubmitted =
    data.is_submitted || data.submitted || data.status === "submitted";
  return (
    <div className="d-flex gap-2 justify-content-end">
      {!isSubmitted && requiresSubmitted && (
        <AppButton
          iconBefore={<AiOutlineUpCircle />}
          key="submit"
          loading={storeActions.submitting}
          loadingText="Submitting..."
          onClick={() => {
            storeActions.submit(data.id);
          }}
          text="Submit"
        />
      )}
      {((data.status === "pending" && !requiresSubmitted) ||
        (isSubmitted && data.status !== "approved")) && (
        <AppButton
          iconBefore={<AiOutlineUpCircle />}
          key="review"
          type="default"
          loading={storeActions.checking}
          loadingText="Reviewing..."
          onClick={() => {
            storeActions.check(data.id);
          }}
          text="Review/Check"
        />
      )}
      {data.status === "checked" && (
        <AppButton
          iconBefore={<AiOutlineUpCircle />}
          loading={storeActions.approving}
          loadingText="Approving..."
          key="approve"
          onClick={() => {
            storeActions.approve(data.id);
          }}
          text="Approve"
        />
      )}
      {hasRejection && data.status !== "approved" && (
        <AppButton
          iconBefore={<AiOutlineCloseCircle />}
          text="Reject"
          danger
          onClick={storeActions.reject}
        />
      )}
      {data.status === "approved" && (
        <AppButton
          iconBefore={<AiOutlineCloseCircle />}
          text="Undo Approval"
          danger
          onClick={() => storeActions.undoApprove(data.id)}
        />
      )}
    </div>
  );
};

export default RecordStatusButtons;
