import { Card, Form } from "antd";
import React, { useEffect } from "react";
import AppInput from "../../../common/AppInput";
import AppButton from "../../../common/AppButton";
import { useReportingTemplateStore } from "../../../../config/stores";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import advanceAccountabilityFormThunks from "../../../../config/thunks/finance/advanceAccountabilityForm/advanceAccountabilityForm.thunks";

const ActionPointsForm = ({ reportingTemplate = {} }) => {
  const {
    creatingActionPoint,
    actionPointToUpdate,
    addActionPoints,
    updateActionPoints,
    addingActionPoint,
    updatingActionPoint,
    setCreatingActionPoint,
    updatingActionPointError,
    creatingActionPointError,
  } = useReportingTemplateStore();
  const {
    advanceAccountabilityFormsDetails,
    advanceAccountabilityFormsDetailsLoading,
  } = useSelector((state) => state.advanceAccountabilityFormsState);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!creatingActionPoint && !isEmpty(actionPointToUpdate)) {
      form.setFieldsValue(actionPointToUpdate);
    } else {
      form.resetFields();
    }
  }, [creatingActionPoint, actionPointToUpdate, form]);

  const resetFields = () => {
    setCreatingActionPoint();
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(
        advanceAccountabilityFormsDetails.id
      )
    );
  };

  const onSave = async (values) => {
    values.activity_reporting_template_id = reportingTemplate.id;
    if (creatingActionPoint) {
      await addActionPoints(values);
      if (isEmpty(creatingActionPointError)) resetFields();
    } else {
      await updateActionPoints(actionPointToUpdate.id, values);
      if (isEmpty(updatingActionPointError)) resetFields();
    }
  };
  return (
    <div className="px-2">
      <Card title={`${creatingActionPoint ? "CREATE" : "EDIT"} ACTION POINT`}>
        <Form onFinish={onSave} form={form} layout="vertical">
          <AppInput
            name="action_point"
            label="Action Point"
            rules={[{ required: true, message: "Missing action point" }]}
          />
          <AppInput
            name="person_responsible"
            label="Person Responsible"
            rules={[{ required: true, message: "Missing person responsible" }]}
          />
          <AppInput
            name="status"
            type="select"
            label="Status"
            rules={[{ required: true, message: "Missing status" }]}
            options={[
              { label: "Pending", value: "PENDING" },
              { value: "WORK_IN_PROGRESS", label: "Work In Progress" },
              { value: "COMPLETED", label: "Completed" },
            ]}
          />
          <AppInput
            name="timeline"
            label="Timeline"
            rules={[{ required: true, message: "Missing person responsible" }]}
          />
          <AppButton
            loading={
              addingActionPoint ||
              updatingActionPoint ||
              advanceAccountabilityFormsDetailsLoading
            }
            disabled={isEmpty(reportingTemplate)}
            text="Save"
            loadingText="Saving"
          />
        </Form>
      </Card>
    </div>
  );
};

export default ActionPointsForm;
