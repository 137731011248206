import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

const initialState = {
  reportingTemplates: {},
  gettingReportingTemplates: false,
  reportTemplatesError: null,

  createdReportTemplate: {},
  creatingReportTemplate: false,
  createReportTemplateError: null,

  creating: false,

  projectReportingTemplates: {},
  gettingProjectReportingTemplates: false,
  projectReportTemplatesError: null,

  updatingReportTemplate: false,
  updatingReportTemplateError: null,
  reportingTemplateToUpdate: {},

  showCreating: false,

  deletingReportTemplate: false,
  deletingReportTemplateError: null,

  reportingTemplate: {},
  gettingReportingTemplate: false,
  reportTemplateError: null,

  addingIndicator: false,
  creatingIndicatorError: null,
  createdIndicator: {},

  updatingIndicator: false,
  updatingIndicatorError: {},

  deletingIndicator: false,
  deletingIndicatorError: {},
  deletedIndicator: {},

  addingActionPoint: false,
  creatingActionPointError: null,
  createdActionPoint: {},

  creatingActionPoint: true,

  updatingActionPoint: false,
  updatingActionPointError: {},
  actionPointToUpdate: {},

  deletingActionPoint: false,
  deletingActionPointError: {},

  addingComment: false,
  creatingCommentError: null,
  createdComment: {},

  updatingComment: false,
  updatingCommentError: {},
  updatedComment: {},

  deletingComment: false,
  deletingCommentError: {},
  deletedComment: {},

  submittingReportingTemplate: false,
  submitError: {},

  checkingTemplate: false,
  checkTemplateError: {},

  approvingReportingTemplate: false,
  approveReportingTemplateError: {},

  undoingReportTemplateApproval: false,
  undoApproveError: false,
};

const store = (set, get) => ({
  ...initialState,

  setCreatingActionPoint: (creating = true, updateData = {}) => {
    set({
      creatingActionPoint: creating,
      actionPointToUpdate: updateData,
    });
  },

  submitReportingTemplate: async (id) => {
    set({ submittingReportingTemplate: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/submit/${id}`,
        method: "PATCH",
      });
      set({
        submittingReportingTemplate: false,
      });
    } catch (error) {
      set({
        submittingReportingTemplate: false,
        submitError: error,
      });
    }
  },

  checkReportingTemplate: async (id) => {
    set({ checkingTemplate: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/check/${id}`,
        method: "PATCH",
      });
      set({
        checkingTemplate: false,
      });
    } catch (error) {
      set({
        checkingTemplate: false,
        checkTemplateError: error,
      });
    }
  },
  approveReportingTemplate: async (id) => {
    set({ approvingReportingTemplate: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/approve/${id}`,
        method: "PATCH",
      });
      set({
        approvingReportingTemplate: false,
      });
    } catch (error) {
      set({
        approvingReportingTemplate: false,
        approveReportingTemplateError: error,
      });
    }
  },
  undoApproval: async (id) => {
    set({ undoingReportTemplateApproval: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/undo-approval/${id}`,
        method: "PATCH",
      });
      set({
        undoingReportTemplateApproval: false,
      });
    } catch (error) {
      set({
        undoingReportTemplateApproval: false,
        undoApproveError: error,
      });
    }
  },

  //actions
  getReportingTemplates: async () => {
    set({ gettingReportingTemplates: true });
    try {
      const response = await axios({
        url: "/programs-mgt/activity-reporting-templates",
        method: "GET",
      });
      set({
        gettingReportingTemplates: false,
        reportingTemplates: response,
      });
    } catch (error) {
      set({
        gettingReportingTemplates: false,
        reportTemplatesError: error,
      });
    }
  },

  createReportingTemplate: async (data) => {
    set({ creatingReportTemplate: true });
    try {
      const response = await axios({
        url: "/programs-mgt/activity-reporting-templates",
        method: "POST",
        data,
      });
      get().getProjectReportingTemplate(data.project_id);
      set({
        creatingReportTemplate: false,
        createReportTemplate: response,
      });
    } catch (error) {
      set({
        creatingReportTemplate: false,
        createReportTemplateError: error,
      });
    }
  },

  getProjectReportingTemplate: async (projectId) => {
    set({ gettingProjectReportingTemplates: true });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/project/${projectId}`,
        method: "GET",
      });
      set({
        gettingProjectReportingTemplates: false,
        projectReportingTemplates: response,
      });
    } catch (error) {
      set({
        gettingProjectReportingTemplates: false,
        projectReportTemplatesError: error,
      });
    }
  },

  getReportingTemplate: async (id) => {
    set({ gettingReportingTemplate: true });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/${id}`,
        method: "GET",
      });
      set({
        gettingReportingTemplate: false,
        reportingTemplate: response,
      });
    } catch (error) {
      set({
        gettingReportingTemplate: false,
        reportTemplateError: error,
      });
    }
  },

  updateReportingTemplate: async (id, data) => {
    set({ updatingReportTemplate: true });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/${id}`,
        method: "PUT",
        data,
      });
      set({
        updatingReportTemplate: false,
        reportingTemplateToUpdate: response,
      });
    } catch (error) {
      set({
        updatingReportTemplate: false,
        updatingReportTemplateError: error,
      });
    }
  },

  deleteReportingTemplate: async (id) => {
    set({ deletingReportTemplate: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/${id}`,
        method: "DELETE",
      });
      set({
        deletingReportTemplate: false,
      });
    } catch (error) {
      set({
        deletingReportTemplate: false,
        deletingReportTemplateError: error,
      });
    }
  },

  addReportIndicators: async (data) => {
    set({ addingIndicator: true });
    try {
      const response = await axios({
        url: "/programs-mgt/activity-reporting-templates/indicators",
        method: "POST",
        data,
      });
      set({
        addingIndicator: false,
        createdIndicator: response,
      });
    } catch (error) {
      set({
        addingIndicator: false,
        creatingIndicatorError: error,
      });
    }
  },

  updateReportIndicators: async (id, data) => {
    set({ updatingIndicator: true });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/indicators/${id}`,
        method: "PUT",
        data,
      });
      set({
        updatingIndicator: false,
        createdIndicator: response,
      });
    } catch (error) {
      set({
        updatingIndicator: false,
        updatingIndicatorError: error,
      });
    }
  },

  deleteIndicator: async (id) => {
    set({ deletingIndicator: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/indicators/${id}`,
        method: "DELETE",
      });
      set({
        deletingIndicator: false,
      });
    } catch (error) {
      set({
        deletingIndicator: false,
        deletingIndicatorError: error,
      });
    }
  },

  addComments: async (data) => {
    set({ addingComment: true });
    try {
      const response = await axios({
        url: "/programs-mgt/activity-reporting-templates/comments",
        method: "POST",
        data,
      });
      set({
        addingComment: false,
        createdComment: response,
      });
    } catch (error) {
      set({
        addingComment: false,
        creatingCommentError: error,
      });
    }
  },

  updateComments: async (id, data) => {
    set({ updatingComment: true });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/comments/${id}`,
        method: "PUT",
        data,
      });
      set({
        updatingComment: false,
        updatedComment: response,
      });
    } catch (error) {
      set({
        updatingComment: false,
        updatingCommentError: error,
      });
    }
  },

  deleteComments: async (id) => {
    set({ deletingComment: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/comments/${id}`,
        method: "DELETE",
      });
      set({
        deletingComment: false,
      });
    } catch (error) {
      set({
        deletingComment: false,
        deletingCommentError: error,
      });
    }
  },

  addActionPoints: async (data) => {
    set({ addingActionPoint: true, creatingActionPointError: {} });
    try {
      const response = await axios({
        url: "/programs-mgt/activity-reporting-templates/action-points",
        method: "POST",
        data,
      });
      set({
        addingActionPoint: false,
        createdActionPoint: response,
      });
    } catch (error) {
      set({
        addingActionPoint: false,
        creatingActionPointError: error,
      });
    }
  },

  updateActionPoints: async (id, data) => {
    set({ updatingActionPoint: true, updatingActionPointError: {} });
    try {
      const response = await axios({
        url: `/programs-mgt/activity-reporting-templates/action-points/${id}`,
        method: "PUT",
        data,
      });
      set({
        updatingActionPoint: false,
        createdActionPoint: response,
      });
    } catch (error) {
      set({
        updatingActionPoint: false,
        updatingActionPointError: error,
      });
    }
  },

  deleteActionPoints: async (id) => {
    set({ deletingActionPoint: true });
    try {
      await axios({
        url: `/programs-mgt/activity-reporting-templates/action-points/${id}`,
        method: "DELETE",
      });
      set({
        deletingActionPoint: false,
      });
    } catch (error) {
      set({
        deletingActionPoint: false,
        deletingActionPointError: error,
      });
    }
  },

  //reset
  resetReportingTemplateStore: () => {
    set(initialState);
  },
});

const storeName = "useReportingTemplateStore";

const useReportingTemplateStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useReportingTemplateStore;
