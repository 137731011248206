import { Alert, Modal, Spin, Tabs } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvanceAccountabilityFormDocumentsTable from "./AdvanceAccountabilityFormDocumentsTable";
import AdvanceAccountabilityFormExpenditureOtherDetails from "./AdvanceAccountabilityFormExpenditureOtherDetails";
import ActivityReportingTemplate from "../reportingTemplate/ActivityReportingTemplate";
import { find, toUpper } from "lodash";
import RecordStatusButtons from "../../../common/FormStatusButtons";
import { useReportingTemplateStore } from "../../../../config/stores";
import advanceAccountabilityFormThunks from "../../../../config/thunks/finance/advanceAccountabilityForm/advanceAccountabilityForm.thunks";
import ActionPoints from "../ActionPoints/ActionPoints";

const AdvanceAccountabilityFormExpenditureDetails = ({
  handleCloseModal,
  visible,
  handleDelete,
}) => {
  const {
    advanceAccountabilityFormsExpenditureDetailsLoading,
    advanceAccountabilityFormsExpenditureDetailsSuccess,
    advanceAccountabilityFormsExpenditureDetailsError,
    advanceAccountabilityFormsExpenditureDetails,
    advanceAccountabilityFormsDetails,
  } = useSelector((state) => state.advanceAccountabilityFormsState);

  const reportingTemplateStore = useReportingTemplateStore();

  const reportingTemplate = useMemo(() => {
    return (
      find(advanceAccountabilityFormsDetails.reportingTemplates, {
        actual_expenditure_id: advanceAccountabilityFormsExpenditureDetails.id,
      }) || {}
    );
  }, [
    advanceAccountabilityFormsDetails.reportingTemplates,
    advanceAccountabilityFormsExpenditureDetails.id,
  ]);

  const dispatch = useDispatch();

  const reloadPaymentVoucher = () => {
    dispatch(
      advanceAccountabilityFormThunks.getAdvanceAccountabilityFormDetails(
        advanceAccountabilityFormsDetails.id
      )
    );
  };

  return (
    <div>
      <Modal
        title={
          toUpper(advanceAccountabilityFormsExpenditureDetails.name) ||
          "LOADING..."
        }
        open={visible}
        footer={null}
        width={1200}
        onCancel={() => handleCloseModal(false)}
      >
        {advanceAccountabilityFormsExpenditureDetailsLoading ? (
          <div className="justify-content-center d-flex m-5">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {!advanceAccountabilityFormsExpenditureDetailsSuccess ? (
              <Alert
                showIcon
                message={advanceAccountabilityFormsExpenditureDetailsError}
                type="error"
              />
            ) : (
              <Tabs
                tabBarStyle={{
                  marginBottom: 5,
                }}
                tabBarExtraContent={
                  <>
                    <RecordStatusButtons
                      requiresSubmitted
                      data={reportingTemplate}
                      storeActions={{
                        approve: async (id) => {
                          await reportingTemplateStore.approveReportingTemplate(
                            id
                          );
                          reloadPaymentVoucher();
                        },
                        approving:
                          reportingTemplateStore.approvingReportingTemplate,
                        submit: async (id) => {
                          await reportingTemplateStore.submitReportingTemplate(
                            id
                          );
                          reloadPaymentVoucher();
                        },
                        submitting:
                          reportingTemplateStore.submittingReportingTemplate,
                        check: async (id) => {
                          await reportingTemplateStore.checkReportingTemplate(
                            id
                          );
                          reloadPaymentVoucher();
                        },
                        checking: reportingTemplateStore.checkingTemplate,
                        undoingApproval:
                          reportingTemplateStore.undoingReportTemplateApproval,
                        undoApprove: async (id) => {
                          await reportingTemplateStore.undoApproval(id);
                          reloadPaymentVoucher();
                        },
                      }}
                    />
                  </>
                }
                items={[
                  {
                    label: "REPORTING TEMPLATE",
                    key: "reporting-templates",
                    children: <ActivityReportingTemplate />,
                  },
                  {
                    label: "ACTION POINTS",
                    key: "action-points",
                    disabled: !reportingTemplate,
                    children: (
                      <ActionPoints reportingTemplate={reportingTemplate} />
                    ),
                  },
                  {
                    label: "OTHER DETAILS",
                    key: "other-details",
                    children: (
                      <AdvanceAccountabilityFormExpenditureOtherDetails
                        data={advanceAccountabilityFormsExpenditureDetails}
                      />
                    ),
                  },
                  {
                    label: "DOCUMENTS",
                    key: "documents",
                    children: (
                      <AdvanceAccountabilityFormDocumentsTable
                        handleDelete={handleDelete}
                        data={
                          advanceAccountabilityFormsExpenditureDetails.expenseDocuments
                        }
                      />
                    ),
                  },
                ]}
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdvanceAccountabilityFormExpenditureDetails;
