import { Modal, Form, Button, Select, Alert, message, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AppInput from "../../../common/AppInput";
import { inputNumberFormatter } from "../../../../config/constants";

const { Option } = Select;

const AddAdvanceAccountabilityFormExpenditure = ({
  visible,
  handleCloseModal,
  handleAddRecord,
}) => {
  const [form] = Form.useForm();
  const {
    addAdvanceAccountabilityFormsExpenditureLoading,
    addAdvanceAccountabilityFormsExpenditureSuccess,
    addAdvanceAccountabilityFormsExpenditureError,
    addAdvanceAccountabilityFormsExpenditureMessage,
    advanceAccountabilityForms,
    advanceAccountabilityFormsDetails,
  } = useSelector((state) => state.advanceAccountabilityFormsState);

  const onFinish = (values) => {
    values.created_at = new Date();
    values.advance_accountability_form = advanceAccountabilityFormsDetails.id;
    handleAddRecord(values, advanceAccountabilityForms);
  };

  useEffect(() => {
    if (
      addAdvanceAccountabilityFormsExpenditureSuccess &&
      addAdvanceAccountabilityFormsExpenditureMessage
    ) {
      message.success(addAdvanceAccountabilityFormsExpenditureMessage);
      form.resetFields();
    }
  }, [
    addAdvanceAccountabilityFormsExpenditureMessage,
    addAdvanceAccountabilityFormsExpenditureSuccess,
    form,
  ]);
  return (
    <div>
      <Modal
        onCancel={() => handleCloseModal(false)}
        open={visible}
        footer={null}
        title="ADD ACTUAL EXPENDITURE"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {!addAdvanceAccountabilityFormsExpenditureSuccess &&
            addAdvanceAccountabilityFormsExpenditureError && (
              <Alert
                type="error"
                showIcon
                message={addAdvanceAccountabilityFormsExpenditureError}
              />
            )}
          <Form.Item
            rules={[
              { required: true, message: "Please select a requsition memo" },
            ]}
            label="Requisition Form Cost Driver"
            name="requisition_memo_activity"
          >
            <Select>
              {advanceAccountabilityFormsDetails?.requisitonMemoActivities?.map(
                (memo) => (
                  <Option key={memo.id} value={memo.id}>
                    {memo.name}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
          <AppInput
            type="number"
            label="Actual Expenditure"
            name="actual_expenditure"
            rules={[{ required: true, message: "Please add details" }]}
            inputAttributes={{
              formatter: inputNumberFormatter,
            }}
          />
          <Form.Item>
            <Button
              loading={addAdvanceAccountabilityFormsExpenditureLoading}
              htmlType="submit"
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAdvanceAccountabilityFormExpenditure;
