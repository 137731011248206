import { Button, message, Popover, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requisitionMemosActions } from "../../../../config/actions/finance/requisitionMemo/requsitionMemo.actions";
import {
  getCurrency,
  numberWithCommas,
} from "../../../../config/helpers/app/numberFormater";
import generatePDF from "../../../../config/services/generatePdf";
import tableButtonActions from "../../../shared/Tables/TableButtonActions";
import TableTitle from "../../../shared/Tables/TableTitle";

const { Text } = Typography;

const RequisitionMemoActivitesTable = ({
  handleDelete,
  handleRefreshTable,
  handleExportRecords,
  handleOpenAddModal,
  handleOpenEditModal,
  handleViewDetails,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [filterTable, setfilterTable] = useState(null);
  const {
    requisitionMemosDetails: { requisitionMemoActivities: data },
    requisitionMemosDetailsLoading: loading,
    requisitionMemosDetailsSuccess: success,
    requisitionMemosDetailsError: error,
    deleteRequisitionMemosActivityLoading: deleteLoading,
    deleteRequisitionMemosActivitySuccess: deleteSuccess,
    deleteRequisitionMemosActivityError: deleteError,
    deleteRequisitionMemosActivityMessage: deleteMessage,
  } = useSelector((state) => state.requisitionMemoState);
  const { budgetDetails } = useSelector((state) => state.budgetState);
  const currency = getCurrency(budgetDetails, "approved_budget");
  const dispatch = useDispatch();
  const {
    success: logDetailsSuccess,
    error: logDetailsError,
    records,
  } = useSelector((state) => state.logDetailsState.addLogDetails);
  const columns = [
    {
      title: "Cost Driver",
      dataIndex: "activityName",
      key: "activityName",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      render: (units) => units?.toLocaleString(),
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
      key: "project",
      render: (unitCost) => unitCost?.toLocaleString(),
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "project",
      render: (totalCost) => totalCost?.toLocaleString(),
    },
    {
      title: "Action",
      key: "operation",
      render: (text) => (
        <Popover
          trigger="click"
          placement="bottom"
          content={() =>
            tableButtonActions(text, {
              viewDetails,
              updateRecord,
              confirmDelete,
              deleteRecord,
              deleteLoading,
              deleteId,
            })
          }
        >
          <Button>Actions</Button>
        </Popover>
      ),
    },
  ];
  /* Functions */
  const deleteRecord = (e) => setDeleteId(e.target.id);
  const confirmDelete = () => {
    const deleteObjectArray = data?.filter((r) => r.id === deleteId);
    handleDelete(deleteId, deleteObjectArray[0]?.requisition_memo);
  };
  const refreshTable = () => {
    handleRefreshTable();
    if (!success && error) message.error(error);
  };
  const updateRecord = (e) => {
    const requisitionMemosArray = data?.filter((el) => el.id === e.target.id);
    dispatch(
      requisitionMemosActions.editRequisitionMemosAcitvityData(
        requisitionMemosArray[0]
      )
    );
    handleOpenEditModal(true);
  };
  const viewDetails = (e) => handleViewDetails(e.target.id);

  const search = (value) => {
    const filterTable = data?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setfilterTable(filterTable);
  };
  const setfilterTableNull = (e) => {
    if (!e.target.value) setfilterTable(null);
  };

  const showTotal = (total) => `Total: ${total}`;
  const openAddModal = () => {
    handleOpenAddModal(true);
  };
  const exportRecords = () => {
    message.loading("Proccessing document for export please wait ", 3);
    handleExportRecords();
  };
  const generateRecordsPdf = () => {
    const pdfColumns = ["Memo Number", "Project", "Status"];
    let pdfRows = [];
    data?.forEach((record) => {
      const row = [
        record.memo_number,
        record.project,
        record.status.toUpperCase(),
      ];
      pdfRows.push(row);
    });
    generatePDF(
      pdfRows,
      pdfColumns,
      `Amani Intiative Requisition Forms - ${new Date().toLocaleDateString()}`,
      "Amani Intiatice Requisition Forms.pdf"
    );
  };
  /* Use effect */
  useEffect(() => {
    if (logDetailsSuccess && records === "projectRequisitionMemos") {
      message.info("Started download");
      generateRecordsPdf();
    } else if (!logDetailsSuccess && logDetailsError)
      message.error(logDetailsError);
    if (deleteSuccess && deleteMessage) message.success(deleteMessage);
    else if (!deleteSuccess && deleteError) message.error(deleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    logDetailsSuccess,
    logDetailsError,
    deleteSuccess,
    deleteMessage,
    deleteError,
  ]);

  return (
    <div>
      <Table
        pagination={{
          total: data?.length,
          showSizeChanger: true,
          showTotal,
        }}
        loading={loading}
        title={() =>
          TableTitle({
            search,
            setfilterTableNull,
            refreshTable,
            exportRecords,
            openAddModal,
          })
        }
        bordered={true}
        dataSource={filterTable === null ? data : filterTable}
        columns={columns}
        scroll={{ x: true }}
        summary={(records) => {
          let subTotal = 0;
          records.forEach((record) => {
            subTotal += record.total_cost;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text type="warning">Sub Total:</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text type="warning">{`${
                    currency === "D" ? "USD" : "UGX"
                  } ${numberWithCommas(subTotal)}`}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default RequisitionMemoActivitesTable;
