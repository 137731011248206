import React from "react";
import logo from "../../assets/logo/authLogo.png";
import { Image } from "antd";

const PrintOutHeader = () => {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <Image preview={false} height={50} width={150} src={logo} />
      </div>
      <p className="text-center text-muted mb-0">
        P.O.BOX 610029, Maracha, Uganda |Adongoro Cell, Maracha Town Council
      </p>
      <p className="text-center text-muted mb-0 fw-bold">
        Tel:{" "}
        <span className="text-primary">+256 794728054/ +256 703154084</span> |
        Mob: <span className="text-primary">+256 784951295</span>
      </p>
      <p className="text-center  text-muted mb-0">
        Web: <span className="text-primary">www.amaniinitiative.org</span>{" "}
        |Email:{" "}
        <span className="text-primary">info.amaniinitiative@gmail.com</span>
      </p>
    </div>
  );
};

export default PrintOutHeader;
