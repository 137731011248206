import React, { useEffect } from "react";
import { useTimeSheetStore } from "../../../../config/stores";
import usePrevious from "../../../../components/hooks/usePrevious";
import dayjs from "dayjs";
import AppButton from "../../../../components/common/AppButton";
import AntdModal from "../../../../components/common/AntdModal";
import { Form, message } from "antd";
import AppInput from "../../../../components/common/AppInput";

const TimeSheetActivitiesForm = () => {
  const timeSheetsStore = useTimeSheetStore();
  const {
    addingTimeSheetActivity,
    createdTimeSheetActivity,
    timeSheetActivityToEdit,
    showAddingTimeSheetActivity,
    timeSheetDetails,
  } = timeSheetsStore;
  const [form] = Form.useForm();
  const prevState = usePrevious({ createdTimeSheetActivity });

  const isShared = Form.useWatch("is_shared", form);

  const closeModal = () => {
    timeSheetsStore.setAddingTimeSheetActivity();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((data) => {
        if (addingTimeSheetActivity) {
          const payload = {
            activities: [data],
            timeSheetId: timeSheetDetails.id,
          };
          timeSheetsStore.createTimeSheetActivity(payload, timeSheetDetails.id);
        } else {
          timeSheetsStore.updateTimeSheetActivity(
            data,
            timeSheetActivityToEdit.id,
            timeSheetDetails.id
          );
        }
      })
      .catch((err) => {
        message.error("Please fill in all fields");
      });
  };

  useEffect(() => {
    if (
      prevState &&
      createdTimeSheetActivity &&
      prevState.createdTimeSheetActivity !== createdTimeSheetActivity
    ) {
      form.resetFields();
    }
  }, [prevState, form, createdTimeSheetActivity]);

  useEffect(() => {
    if (addingTimeSheetActivity) {
      form.setFieldsValue({
        date: dayjs(),
      });
    } else {
      form.setFieldsValue({
        date: dayjs(timeSheetActivityToEdit.date),
        activity: timeSheetActivityToEdit.activity,
        is_shared: timeSheetActivityToEdit.is_shared,
        project_id: timeSheetActivityToEdit.project_id,
        hours: timeSheetActivityToEdit.hours,
      });
    }
  }, [addingTimeSheetActivity, timeSheetActivityToEdit, form]);

  const currentMonth =
    toString(timeSheetDetails).length > 1
      ? `0${timeSheetDetails.month}`
      : timeSheetDetails.month;

  const currentPeriod = dayjs(
    `${currentMonth}/${timeSheetDetails.year}`,
    "MM/YYYY"
  );
  const startOfMonth = currentPeriod.startOf("month");
  const endOfMonth = currentPeriod.endOf("month");

  // Function to disable dates outside the current period
  const disabledDate = (currentDate) => {
    return (
      currentDate.isBefore(startOfMonth) || currentDate.isAfter(endOfMonth)
    );
  };

  return (
    <div>
      <AntdModal
        title={
          addingTimeSheetActivity
            ? "ADD TIME SHEET ACTIVITY"
            : "EDIT TIME SHEET ACTIVITY"
        }
        visible={showAddingTimeSheetActivity}
        closeModal={closeModal}
        size="large"
        footer={[
          <AppButton
            text={
              addingTimeSheetActivity
                ? "ADD TIME SHEET ACTIVITY"
                : "EDIT TIME SHEET ACTIVITY"
            }
            key={"create"}
            loading={
              timeSheetsStore.creatingTimeSheetActivity ||
              timeSheetsStore.updatingTimeSheetActivity
            }
            onClick={onSubmit}
          />,
          <AppButton
            text="CANCEL"
            key={"cancel"}
            onClick={closeModal}
            danger
          />,
        ]}
        zIndex={1000}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          form={form}
        >
          <AppInput
            type="date"
            label="Date"
            name="date"
            rules={[{ required: true }]}
            inputAttributes={{
              disabledDate,
            }}
          />
          <AppInput
            type="select"
            label="Is Shared ?"
            name="is_shared"
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            rules={[{ required: true }]}
          />
          {!isShared && (
            <>
              <AppInput
                type="select"
                label="Project"
                name="project_id"
                options={timeSheetDetails.projects?.map((project) => ({
                  label: project.name,
                  value: project.id,
                }))}
                rules={[{ required: true }]}
              />
            </>
          )}

          <AppInput
            name="hours"
            type="number"
            label="Hours"
            rules={[{ required: true }]}
            inputAttributes={{
              addonAfter: "Hr(s)",
              min: 1,
              max: 24,
            }}
          />
          <AppInput
            name="activity"
            type="textarea"
            label="Activity"
            rules={[{ required: true }]}
          />
        </Form>
      </AntdModal>
    </div>
  );
};

export default TimeSheetActivitiesForm;
