import React, { useEffect, useState } from "react";
import { useChildIncidentProtectionFormStore } from "../../../config/stores";
import { searchTable } from "../../../config/constants";
import TableTitle from "../../../components/common/TableTitle";
import AntdTable from "../../../components/common/AntdTable";
import TableButtonActions from "../../../components/common/TableButtonActions";
import moment from "moment";
import { isEmpty } from "lodash";
import ChildProtectionIncidentForm from "./ChildProtectionIncidentForm";
import { Tag } from "antd";
import { useSelector } from "react-redux";

const ChildProtectionIncidentForms = () => {
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const [filterTable, setFilterTableNull] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const incidentFormStore = useChildIncidentProtectionFormStore();
  const { childProtectionIncidentForms, showChildProtectionForm, showDetails } =
    incidentFormStore;

  const updateRecord = (record) => {
    incidentFormStore.setCreatingData(false, true, record);
  };
  const viewDetails = (id) => {
    incidentFormStore.setShowDetails(true);
    incidentFormStore.getChildProtectionIncidentFormDetails(id);
  };

  const search = (value) => {
    const filterTable = searchTable(
      childProtectionIncidentForms.childProtectionIncidentForms,
      value
    );
    setFilterTableNull(filterTable);
  };

  const confirmDelete = () => {
    incidentFormStore.deleteChildProtectionIncidentForm(deleteId);
  };

  const deleteRecord = (id) => setDeleteId(id);

  const openAddModal = () => {
    incidentFormStore.setCreatingData(true, true, {});
  };

  const refreshTable = () => {
    incidentFormStore.getChildProtectionIncidentForms();
  };

  useEffect(() => {
    if (isEmpty(childProtectionIncidentForms)) {
      incidentFormStore.getChildProtectionIncidentForms();
      incidentFormStore.getMetaData();
    }
  }, []);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">CHILD PROTECTION INCIDENT FORMS</h5>

      {showChildProtectionForm || showDetails ? (
        <ChildProtectionIncidentForm />
      ) : (
        <AntdTable
          loading={incidentFormStore.gettingChildProtectionForms}
          title={() => (
            <TableTitle
              setFilterTableNull={setFilterTableNull}
              openAddModal={openAddModal}
              refreshTable={refreshTable}
              search={search}
              exportRecords={null}
            />
          )}
          pageSize={20}
          data={
            filterTable === null
              ? childProtectionIncidentForms.childProtectionIncidentForms || []
              : filterTable
          }
          columns={[
            {
              title: "Name of Victim",
              render: (record) => {
                return (
                  <div>
                    <span className="d-md-none">Name of victim:</span>
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => viewDetails(record.id)}
                    >
                      {`${record?.name_of_victim}`}
                    </span>{" "}
                    <br />
                    <div className="d-md-none">
                      Name Of Perpetrator : {record.name_of_perpetrator} <br />
                      Status: {record.status} <br />
                      Actions:
                      <TableButtonActions
                        record={record}
                        confirmDelete={confirmDelete}
                        deleteLoading={
                          incidentFormStore.deletingChildIncidentReportingForm
                        }
                        viewDetails={viewDetails}
                        updateRecord={updateRecord}
                        deleteId={deleteId}
                        deleteRecord={deleteRecord}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              title: "Name of Perpetrator",
              key: "date",
              dataIndex: "name_of_perpetrator",
              responsive: ["md"],
            },
            {
              title: "Reported By",
              key: "created_by",
              dataIndex: "created_by",
              render: (data) =>
                `${data?.first_name || ""} ${data?.other_names || ""}`,
              responsive: ["md"],
            },
            {
              title: "Assigned To",
              key: "assigned_to",
              dataIndex: "assigned_to",
              render: (data) =>
                `${data?.first_name || ""} ${data?.other_names || ""}`,
              responsive: ["md"],
            },
            {
              title: "Date",
              key: "date",
              dataIndex: "date_of_incident",
              render: (text) => moment(text).format("DD/MM/YYYY"),
              responsive: ["md"],
            },
            {
              title: "Is Submitted ?",
              key: "is_submitted",
              dataIndex: "is_submitted",
              render: (text) =>
                text ? (
                  <Tag color="success">Yes</Tag>
                ) : (
                  <Tag color="error">No</Tag>
                ),
              responsive: ["md"],
            },
            {
              title: "Actions",
              key: "actions",
              responsive: ["md"],
              render: (record) => {
                return (
                  <TableButtonActions
                    record={record}
                    confirmDelete={confirmDelete}
                    deleteLoading={
                      incidentFormStore.deletingChildIncidentReportingForm
                    }
                    viewDetails={viewDetails}
                    updateRecord={
                      currentUserInfo.id === record.created_by_id
                        ? updateRecord
                        : null
                    }
                    deleteId={deleteId}
                    deleteRecord={
                      record.status !== "CLOSED" ? deleteRecord : null
                    }
                  />
                );
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default ChildProtectionIncidentForms;
