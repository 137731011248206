import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";
import { produce } from "immer";
import { findIndex } from "lodash";
import fileDownload from "js-file-download";

const initialState = {
  cashbookContext: {},
  gettingCashbook: false,
  cashbookError: {},
  cashbook: [],

  projectCashbookContext: {},
  gettingProjectCashbook: false,
  projectCashbookError: {},
  projectCashbook: [],

  downloadingCashbook: false,
  downloadCashbookError: {},

  metadata: {},
  gettingMetaData: false,
  metaDataError: {},
};

const store = (set, get) => ({
  ...initialState,

  // actions

  setProjectCashbookContext(context) {
    set({ projectCashbookContext: context });
  },

  setCashbookContext(context) {
    set({ cashbookContext: context });
  },

  async getCashbook(context) {
    set({ gettingCashbook: true, cashbookError: {} });
    await axios
      .get("/finance/cash-book", {
        params: context,
      })
      .then((res) => {
        const records = get().cashbook;

        const index = findIndex(
          records,
          (record) =>
            record.start_date === context.start_date &&
            record.end_date === context.end_date
        );

        if (index > -1) {
          set(
            produce((state) => {
              state.cashbook[index] = {
                data: res,
                ...context,
              };
            })
          );
        } else {
          set(
            produce((state) => {
              state.cashbook.push({
                data: res,
                ...context,
              });
            })
          );
        }
      })
      .catch((err) => {
        set({ cashbookError: err });
      })
      .finally(() => set({ gettingCashbook: false }));
  },

  async getProjectCashbook(context) {
    set({ gettingProjectCashbook: true, projectCashbookError: {} });
    await axios
      .get(`/finance/cash-book/project/${context.projectId}`, {
        params: context,
      })
      .then((res) => {
        const records = get().projectCashbook;

        const index = findIndex(
          records,
          (record) =>
            record.start_date === context.start_date &&
            record.end_date === context.end_date &&
            record.projectId === context.projectId
        );

        if (index > -1) {
          set(
            produce((state) => {
              state.projectCashbook[index] = {
                data: res,
                ...context,
              };
            })
          );
        } else {
          set(
            produce((state) => {
              state.projectCashbook.push({
                data: res,
                ...context,
              });
            })
          );
        }
      })
      .catch((err) => {
        set({ projectCashbookError: err });
      })
      .finally(() => set({ gettingProjectCashbook: false }));
  },

  async downloadCashbook(context, fileName = "AMANI INITIATIVE CASHBOOK") {
    set({ downloadingCashbook: true, downloadCashbookError: {} });
    await axios
      .get("/finance/cash-book/download-excel", {
        params: context,
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      })
      .catch((err) => {
        set({ downloadCashbookError: err });
      })
      .finally(() => set({ downloadingCashbook: false }));
  },

  async getMetaData() {
    set({ gettingMetaData: true, metaDataError: {} });
    await axios
      .get("/finance/cash-book/meta-data")
      .then((res) => {
        set({ metadata: res });
      })
      .catch((err) => {
        set({ downloadCashbookError: err });
      })
      .finally(() => set({ gettingMetaData: false }));
  },
});

const storeName = "Cashbook Store";

const useCashbookStore = create(devtools(store, { storeName }), { storeName });

export default useCashbookStore;
