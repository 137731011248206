import React, { useEffect } from "react";
import { useCashbookStore } from "../../../config/stores";
import { Form, message } from "antd";
import AppInput from "../../../components/common/AppInput";
import AppButton from "../../../components/common/AppButton";
import dayjs from "dayjs";
import { map } from "lodash";

const CashbookContext = ({ showProjects = false }) => {
  const {
    cashbookContext,
    gettingCashbook,
    projectCashbookContext,
    setCashbookContext,
    getCashbook,
    getProjectCashbook,
    setProjectCashbookContext,
    metadata,
  } = useCashbookStore();

  const [form] = Form.useForm();

  useEffect(() => {
    let formData = {};
    if (showProjects) {
      const startDate = dayjs(projectCashbookContext.startDate, "YYYY-MM-DD");
      const endDate = dayjs(projectCashbookContext.endDate, "YYYY-MM-DD");
      formData = {
        startDate: startDate.isValid() ? startDate : null,
        endDate: endDate.isValid() ? endDate : null,
        projectId: projectCashbookContext?.projectId,
      };
    } else {
      const startDate = dayjs(cashbookContext.startDate, "YYYY-MM-DD");
      const endDate = dayjs(cashbookContext.endDate, "YYYY-MM-DD");
      formData = {
        startDate: startDate.isValid() ? startDate : null,
        endDate: endDate.isValid() ? endDate : null,
      };
    }
    form.setFieldsValue(formData);
  }, [cashbookContext, showProjects]);

  const onSubmit = (values) => {
    if (values.startDate.isSame(values.endDate, "day")) {
      return message.error("Start Date Cannot be same as end date");
    }
    if (values.endDate.isBefore(values.startDate, "day")) {
      return message.error("End Date cannot be before start date");
    }

    const context = {
      startDate: values.startDate.format("YYYY-MM-DD"),
      endDate: values.endDate.format("YYYY-MM-DD"),
    };

    if (showProjects) {
      context.projectId = values.projectId;
    }

    if (showProjects) {
      setProjectCashbookContext(context);
      getProjectCashbook(context);
    } else {
      setCashbookContext(context);
      getCashbook(context);
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onSubmit} className="py-2" layout="inline">
        {showProjects && (
          <AppInput
            name="projectId"
            type="select"
            label="PROJECT"
            rules={[{ required: true, message: "Missing project" }]}
            options={map(metadata?.projects, (project) => ({
              value: project.id,
              label: project.name,
            }))}
          />
        )}
        <AppInput
          name="startDate"
          label="Start Date"
          rules={[{ required: true, message: "Missing start date" }]}
          type="date"
          inputAttributes={{
            disabledDate: (current) =>
              current && current <= new Date("2024-12-31"),
          }}
        />
        <AppInput
          name="endDate"
          label="End Date"
          rules={[{ required: true, message: "Missing end date" }]}
          type="date"
          inputAttributes={{
            disabledDate: (current) =>
              current && current <= new Date("2024-12-31"),
          }}
        />
        <AppButton
          text="GET CASHBOOK"
          loadingText="Getting Cashbook..."
          loading={gettingCashbook}
        />
      </Form>
    </div>
  );
};

export default CashbookContext;
