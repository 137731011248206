import { Card, Form, message, Popconfirm, Row, Space, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useChildIncidentProtectionFormStore } from "../../../config/stores";
import AppButton from "../../../components/common/AppButton";
import {
  AiOutlineArrowUp,
  AiOutlineClose,
  AiOutlinePrinter,
  AiOutlineSend,
  AiOutlineUser,
} from "react-icons/ai";
import { useSelector } from "react-redux";
import AppInput from "../../../components/common/AppInput";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import AssignUser from "./AssignUser";
import PrintOutHeader from "../../../components/shared/PrintOutHeader";
import { useReactToPrint } from "react-to-print";

const ChildProtectionIncidentForm = () => {
  const incidentFormStore = useChildIncidentProtectionFormStore();
  const [showHeader, setShowHeader] = useState(false);
  const { data: currentUserInfo } = useSelector(
    (state) => state.usersState.currentUserInfo
  );
  const {
    creatingData,
    formToUpdate,
    showDetails,
    childProtectionFormDetails,
    gettingChildProtectionFormDetails,
    settingChildIncidentReportingFormStatus,
    creatingChildIncidentReportingForm,
    updatingChildIncidentReportingForm,
  } = incidentFormStore;
  const [form] = Form.useForm();

  useEffect(() => {
    if (creatingData && !showDetails) {
      form.setFieldsValue({
        date_of_incident: dayjs(),
        status: "OPEN",
      });
    } else if (!creatingData && !isEmpty(formToUpdate)) {
      form.setFieldsValue({
        ...formToUpdate,
        date_of_incident: dayjs(formToUpdate.date_of_incident),
      });
    } else if (showDetails) {
      form.setFieldsValue({
        ...childProtectionFormDetails,

        date_of_incident: dayjs(formToUpdate.date_of_incident),
      });
    }
  }, [
    creatingData,
    formToUpdate,
    form,
    showDetails,
    childProtectionFormDetails,
  ]);
  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (creatingData) {
          incidentFormStore.createChildProtectionIncidentForm(values);
        } else {
          incidentFormStore.updateChildProtectionIncidentFormDetails(
            formToUpdate.id || childProtectionFormDetails.id,
            values
          );
        }
      })
      .catch(() => {
        message.error("Please fill in all fields correctly");
      });
  };

  const showSubmit =
    formToUpdate.is_submitted || childProtectionFormDetails.is_submitted;

  const formStatus = formToUpdate.status || childProtectionFormDetails.status;

  const submit = () => {
    incidentFormStore.submitForm(
      childProtectionFormDetails.id || formToUpdate.id
    );
  };

  const closeIncidentForm = () => {
    incidentFormStore.setFormStatus(
      formToUpdate.id || childProtectionFormDetails.id,
      {
        status: "CLOSED",
      }
    );
  };

  const assignedTo =
    formToUpdate.assigned_to || childProtectionFormDetails.assigned_to;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    contentRef: componentRef,
    onAfterPrint: () => {
      setShowHeader(false);
    },
  });
  const onPrint = () => {
    setShowHeader(true);
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  return (
    <div>
      <Card
        loading={gettingChildProtectionFormDetails}
        type="inner"
        title={
          <>
            {showDetails
              ? "CHILD PROTECTION INCIDENT FORM DETAILS"
              : `${
                  creatingData ? "CREATE" : "UPDATE"
                } PROTECTION INCIDENT REPORT FORM `}
            {!creatingData && (
              <Tag className="mx-2" color="blue">
                STATUS:{" "}
                {formToUpdate.status || childProtectionFormDetails.status}
              </Tag>
            )}

            {!isEmpty(assignedTo) && (
              <Tag color="warning" className="text-uppercase">
                ASSIGNED TO: {assignedTo?.first_name || ""}{" "}
                {assignedTo?.other_names || ""}
              </Tag>
            )}
          </>
        }
        extra={
          <Space>
            {!showSubmit && !creatingData && (
              <Popconfirm
                title="Are you sure you want to submit"
                okText="Yes"
                cancelText="No"
                onConfirm={submit}
              >
                <AppButton
                  disabled={creatingData}
                  iconBefore={<AiOutlineArrowUp />}
                  text="Submit"
                />
              </Popconfirm>
            )}
            {showSubmit && formStatus !== "CLOSED" && (
              <Space>
                <Popconfirm
                  title="Are you sure you want to close this incident"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={closeIncidentForm}
                >
                  <AppButton
                    iconBefore={<AiOutlineSend />}
                    text="CLOSE INCIDENT"
                    loading={settingChildIncidentReportingFormStatus}
                    loadingText="Closing..."
                    disabled={!showDetails}
                  />
                </Popconfirm>

                <AppButton
                  type="dashed"
                  iconBefore={<AiOutlineUser />}
                  text="ASSIGN PERSONNEL"
                  disabled={!showDetails}
                  onClick={() => {
                    incidentFormStore.setFormAction("ASSIGN USER", true);
                  }}
                />
              </Space>
            )}
            {!creatingData && (
              <AppButton
                iconBefore={<AiOutlinePrinter />}
                text="Download"
                loading={showHeader}
                loadingText="Downloading..."
                onClick={onPrint}
              />
            )}
            <AppButton
              onClick={() => incidentFormStore.setCreatingData()}
              danger
              text="Close"
              iconBefore={<AiOutlineClose />}
            />
          </Space>
        }
      >
        <div className="p-1" ref={componentRef}>
          {showHeader && <PrintOutHeader />}
          <div className="p-3">
            <p className="text-justified">
              If you have knowledge of an incident that has occurred that is
              against the Code of Conduct, or that a child’s safety might be in
              danger, please complete this form to the best of your knowledge as
              promptly as possible. The rest of the information can be filled
              out later. Please note for confidentiality reasons, the report
              should be written and signed solely by you. Please submit it to
              the Child Protection focal person. This form will be held in a
              secure location and will be treated in the strictest confidence.
              You will be informed how the Child Protection focal person is
              responding to this incident.
            </p>
            <Form form={form} layout="vertical" onFinish={onSave}>
              <table className="table table-sm table-responsive table-bordered">
                <thead>
                  <tr>
                    <th className="w-50">
                      <p className="mb-0 fw-normal">Your Name</p>
                      <p className="mb-0 mx-1">
                        {currentUserInfo.first_name}{" "}
                        {currentUserInfo.other_names}
                      </p>
                    </th>
                    <th className="w-50">
                      <p className="mb-0 fw-normal">Your job title</p>
                      <p className="mb-0 mx-1">{currentUserInfo.role}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className="mb-0">
                        Your e-mail address and/or telephone number
                      </p>
                      <p className="fw-bold mx-1">{currentUserInfo.email}</p>
                    </td>
                    <td>
                      <AppInput
                        name="name_of_perpetrator"
                        label="Name of alleged perpetrator"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter Name",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AppInput
                        name="name_of_victim"
                        label="Name of participant(s)/victim(s) against whom the incident occurred"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter participant/victim",
                        }}
                      />
                    </td>
                    <td>
                      <Row>
                        <AppInput
                          name="gender_of_participant"
                          label="Gender of participant"
                          type="select"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "Enter Gender",
                          }}
                        />
                        <AppInput
                          name="age_of_participant"
                          label="Age of participant"
                          type="number"
                          inputAttributes={{
                            variant: "borderless",
                            placeholder: "Enter age",
                            min: 0,
                            max: 100,
                          }}
                        />
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AppInput
                        name="address_of_participant"
                        label="Address of participant "
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter address",
                        }}
                      />
                    </td>
                    <td>
                      <AppInput
                        name="location_of_incident"
                        label="Location of alleged incident"
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter location",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AppInput
                        name="guardians_of_participant"
                        label="Guardians/carers of participant (if a child)"
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter Guardians/carers ",
                        }}
                      />
                    </td>
                    <td>
                      <AppInput
                        name="date_of_incident"
                        label="Date, time of alleged incident"
                        type="date"
                        inputAttributes={{
                          variant: "borderless",
                          placeholder: "Enter date and time",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <AppInput
                        name="status"
                        label="Status"
                        inputAttributes={{
                          variant: "borderless",
                          readOnly: true,
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="fw-bold">
                Please describe the nature of the allegation below (and on a
                separate page if required). Try to answer the following
                questions (use another page if needed).
              </p>
              <ul>
                <li>
                  Was the abuse observed, suspected or divulged to you by
                  another party?
                </li>
                <li>
                  What was/is the participant’s physical and emotional state?
                </li>
                <li>
                  Has the participant said anything to you, and how did you
                  respond?
                </li>
                <li>
                  Were there any other people involved? What response have you
                  taken, if any, to the alleged incident? Which other parties,
                  if any, are also aware of the alleged incident
                </li>
              </ul>
              <AppInput
                name="nature_of_allegation"
                type="textarea"
                inputAttributes={{
                  placeholder: "Enter enter nature of allegation",
                }}
                rules={[{ required: true, message: "This field is required" }]}
              />
              <AppInput
                name="person_who_can_verify"
                label="Details of other staff members and community members who can verify the incident"
                inputAttributes={{
                  variant: "borderless",
                  placeholder: "Enter Details",
                }}
              />
              {!showHeader && (
                <AppButton
                  disabled={
                    formToUpdate.status === "CLOSED" ||
                    childProtectionFormDetails.status === "CLOSED"
                  }
                  loading={
                    creatingChildIncidentReportingForm ||
                    updatingChildIncidentReportingForm
                  }
                  className="m-2"
                  text="Save"
                  block
                  type="submit"
                />
              )}
            </Form>
            <AssignUser />
            {/* {!showDetails && (
            <Row className="w-100" gutter={2}>
              <AppButton
                className="m-2 w-25"
                text="Save & Submit"
                block
                type="default"
              />
            </Row>
          )} */}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ChildProtectionIncidentForm;
