import { Card, Tabs } from "antd";
import React, { useEffect } from "react";
import GeneralCashbook from "./GeneralCashbook";
import { useCashbookStore } from "../../../config/stores";
import { isEmpty } from "lodash";
import ProjectCashbook from "./ProjectCashbook";

const Cashbook = () => {
  const cashbookStore = useCashbookStore();

  useEffect(() => {
    if (isEmpty(cashbookStore.metadata)) cashbookStore.getMetaData();
  }, []);
  return (
    <div className="container-fluid p-2">
      <h5 className="fw-bold">CASHBOOK</h5>
      <Card>
        <Tabs
          tabBarStyle={{ marginBottom: 0 }}
          type="card"
          items={[
            {
              label: "GENERAL CASHBOOK",
              key: "general-cashbook",
              children: <GeneralCashbook />,
            },
            {
              label: "PROJECT CASHBOOK",
              key: "project-cashbook",
              children: <ProjectCashbook />,
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default Cashbook;
