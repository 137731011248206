export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCurrency(data, number) {
  try {
    return data?.[number]?.[data?.[number]?.length - 1];
  } catch (error) {
    console.error(error);
    return "";
  }
}

export const inputNumberFormatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
