import { create } from "zustand";
import { devtools } from "zustand/middleware";
import axios from "axios";

const initialState = {
  metaData: {},
  gettingMetaData: {},
  metaDataError: {},

  childProtectionIncidentForms: {},
  gettingChildProtectionForms: false,
  childProtectionFormError: {},

  childProtectionFormDetails: {},
  gettingChildProtectionFormDetails: false,
  childProtectionFormDetailsError: {},
  showDetails: false,

  creatingData: true,
  showChildProtectionForm: false,

  creatingChildIncidentReportingForm: false,
  creatingChildIncidentReportingFormError: {},

  updatingChildIncidentReportingForm: false,
  updatingChildIncidentReportingFormError: {},
  formToUpdate: {},

  deletingChildIncidentReportingForm: false,
  deletingChildIncidentReportingFormError: {},

  assigningChildIncidentReportingForm: false,
  assignChildIncidentReportingFormError: {},

  submittingChildIncidentReportingForm: false,
  submitChildIncidentReportingFormError: {},

  settingChildIncidentReportingFormStatus: false,
  setStatusError: {},

  childProtectionFormAction: "",
  showChildProtectionFormStatus: false,
};

const store = (set, get) => ({
  ...initialState,

  async getMetaData() {
    set({ gettingMetaData: false });
    await axios("/programs-mgt/child-protection-incident-forms/meta-data")
      .then((res) => {
        set({ metaData: res, gettingMetaData: false });
      })
      .catch((err) => {
        set({ metaDataError: err });
      });
  },

  getChildProtectionIncidentForms: async () => {
    set({ gettingChildProtectionForms: true, childProtectionFormError: {} });

    await axios
      .get("/programs-mgt/child-protection-incident-forms")
      .then((res) => {
        set({ childProtectionIncidentForms: res });
      })
      .catch((err) => {
        set({ childProtectionFormError: err });
      })
      .finally(() => {
        set({ gettingChildProtectionForms: false });
      });
  },

  setShowDetails: (show = false) => {
    set({ showDetails: show });
  },

  getChildProtectionIncidentFormDetails: async (id) => {
    set({
      gettingChildProtectionFormDetails: true,
      childProtectionFormDetailsError: {},
      showDetails: true,
      formToUpdate: {},
      creatingData: false,
    });

    await axios
      .get(`/programs-mgt/child-protection-incident-forms/${id}`)
      .then((res) => {
        set({
          gettingChildProtectionFormDetails: false,
          childProtectionFormDetails: res,
        });
      })
      .catch((err) => {
        set({ childProtectionFormDetailsError: err });
      })
      .finally(() => {
        set({ gettingChildProtectionFormDetails: false });
      });
  },

  createChildProtectionIncidentForm: async (data) => {
    set({
      creatingChildIncidentReportingForm: true,
      creatingChildIncidentReportingFormError: {},
    });

    await axios
      .post(`/programs-mgt/child-protection-incident-forms`, data)
      .then((res) => {
        get().getChildProtectionIncidentForms();
        get().setCreatingData();
      })
      .catch((err) => {
        set({ creatingChildIncidentReportingFormError: err });
      })
      .finally(() => {
        set({ creatingChildIncidentReportingForm: false, creatingData: true });
      });
  },

  deleteChildProtectionIncidentForm: async (id) => {
    set({
      deletingChildIncidentReportingForm: true,
      deletingChildIncidentReportingFormError: {},
    });

    await axios
      .delete(`/programs-mgt/child-protection-incident-forms/${id}`)
      .then(() => {
        get().getChildProtectionIncidentForms();
      })
      .catch((err) => {
        set({ deletingChildIncidentReportingFormError: err });
      })
      .finally(() => {
        set({ deletingChildIncidentReportingForm: false, creatingData: true });
      });
  },

  updateChildProtectionIncidentFormDetails: async (id, data) => {
    set({
      updatingChildIncidentReportingForm: true,
      updatingChildIncidentReportingFormError: {},
    });

    await axios
      .put(`/programs-mgt/child-protection-incident-forms/${id}`, data)
      .then(() => {
        set({
          formToUpdate: {},
          creatingData: true,
          showChildProtectionForm: false,
        });
        get().setCreatingData();
        get().getChildProtectionIncidentFormDetails(id);
      })
      .catch((err) => {
        set({ showChildProtectionForm: err });
      })
      .finally(() => {
        set({ updatingChildIncidentReportingForm: false });
      });
  },

  setCreatingData(creating = false, showForm = false, formData = {}) {
    set({
      creatingData: creating,
      showChildProtectionForm: showForm,
      formToUpdate: formData,
    });

    if (creating === false) {
      set({ showDetails: false });
    } else {
      set({ childProtectionFormDetails: {} });
    }
  },

  setFormAction: (
    childProtectionFormAction = "",
    showChildProtectionFormStatus = false
  ) => {
    set({
      childProtectionFormAction,
      showChildProtectionFormStatus,
    });
  },

  submitForm: async (id) => {
    set({
      submittingChildIncidentReportingForm: true,
      submitChildIncidentReportingFormError: {},
    });

    await axios
      .patch(`/programs-mgt/child-protection-incident-forms/submit/${id}`)
      .then(() => {
        get().getChildProtectionIncidentFormDetails(id);
      })
      .catch((err) => {
        set({ submitChildIncidentReportingFormError: err });
      })
      .finally(() => {
        set({ submittingChildIncidentReportingForm: false });
      });
  },

  setFormStatus: async (id, data) => {
    set({
      settingChildIncidentReportingFormStatus: true,
      setStatusError: {},
    });
    await axios
      .patch(
        `/programs-mgt/child-protection-incident-forms/set-status/${id}`,
        data
      )
      .then(() => {
        get().getChildProtectionIncidentFormDetails(id);
        get().setFormAction();
      })
      .catch((err) => {
        set({ setStatusError: err });
      })
      .finally(() => {
        set({ settingChildIncidentReportingFormStatus: false });
      });
  },

  assignUserToForm: async (id, data) => {
    set({
      assigningChildIncidentReportingForm: true,
      assignChildIncidentReportingFormError: {},
    });

    await axios
      .patch(`/programs-mgt/child-protection-incident-forms/assign/${id}`, data)
      .then(() => {
        get().getChildProtectionIncidentFormDetails(id);
        get().setFormAction();
      })
      .catch((err) => {
        set({ assignChildIncidentReportingFormError: err });
      })
      .finally(() => {
        set({ assigningChildIncidentReportingForm: false });
      });
  },
});

const storeName = "useChildIncidentProtectionFormStore";

const useChildIncidentProtectionFormStore = create(
  devtools(store, { name: storeName }),
  {
    name: storeName,
  }
);

export default useChildIncidentProtectionFormStore;
