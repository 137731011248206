import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { produce } from "immer";
import axios from "axios";

const initialState = {
  timeSheets: [],
  gettingTimeSheets: false,
  gettingTimeSheetsError: {},

  myTimeSheets: [],
  gettingMyTimeSheets: false,
  myTimeSheetsError: {},

  createdTimeSheet: null,
  creatingTimeSheet: false,
  creatingTimeSheetError: {},
  showAddingTimeSheet: false,

  updatedTimeSheet: null,
  updatingTimeSheet: false,
  updatingTimeSheetError: {},
  timeSheetToEdit: {},

  deletedTimeSheet: null,
  deletingTimeSheet: false,
  deletingTimeSheetError: {},

  gettingTimeSheetDetails: false,
  timeSheetDetails: {},
  getTimeSheetDetailsError: {},
  showTimeSheetDetails: false,

  approvedTimeSheet: null,
  approvingTimeSheet: false,
  approvingTimeSheetError: {},

  addingTimeSheet: false,
  addingTimeSheetActivity: false,

  creatingTimeSheetActivity: false,
  creatingTimeSheetActivityError: {},
  createdTimeSheetActivity: null,
  showAddingTimeSheetActivity: false,

  updatingTimeSheetActivity: false,
  updatingTimeSheetActivityError: {},
  updatedTimeSheetActivity: null,
  timeSheetActivityToEdit: {},

  deletingTimeSheetActivity: false,
  deletingTimeSheetActivityError: {},
  deletedTimeSheetActivity: null,

  timeSheetActivitiesDate: "",
  showTimeSheetActivities: false,

  submittingTimesSheet: false,
  submitTimeSheetError: {},
  submittedTimeSheet: null
};

const store = (set, get) => ({
  ...initialState,

  getTimeSheets: async () => {
    try {
      set(
        produce((state) => {
          state.gettingTimeSheets = true;
          state.gettingTimeSheetsError = {};
        })
      );

      const res = await axios({ url: "/administration/time-sheets" });

      set(
        produce((state) => {
          state.timeSheets = res;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.gettingTimeSheetsError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.gettingTimeSheets = false;
        })
      );
    }
  },

  getMyTimeSheets: async () => {
    try {
      set(
        produce((state) => {
          state.gettingMyTimeSheets = true;
          state.myTimeSheetsError = {};
        })
      );

      const res = await axios({
        url: "/administration/time-sheets/my-time-sheets",
      });

      set(
        produce((state) => {
          state.myTimeSheets = res;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.myTimeSheetsError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.gettingMyTimeSheets = false;
        })
      );
    }
  },

  getTimeSheetDetails: async (id) => {
    try {
      set(
        produce((state) => {
          state.gettingTimeSheetDetails = true;
          state.getTimeSheetDetailsError = {};
        })
      );

      const res = await axios({
        url: `/administration/time-sheets/${id}`,
      });

      set(
        produce((state) => {
          state.timeSheetDetails = res;
        })
      );
    } catch (error) {
      set(
        produce((state) => {
          state.getTimeSheetDetailsError = error.data;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.gettingTimeSheetDetails = false;
        })
      );
    }
  },

  async createTimeSheet(data) {
    try {
      set(
        produce((state) => {
          state.creatingTimeSheet = true;
          state.createdTimeSheet = null;
          state.creatingTimeSheetError = {};
        })
      );

      const res = await axios({
        method: "POST",
        url: "/administration/time-sheets",
        data,
      });

      set(
        produce((state) => {
          state.createdTimeSheet = res;
        })
      );
      get().setAddingTimeSheet();
      get().getMyTimeSheets();
      get().setShowViewTimeSheetDetails(true);
      get().getTimeSheetDetails(res.id);
    } catch (error) {
      set(
        produce((state) => {
          state.creatingTimeSheetError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.creatingTimeSheet = false;
        })
      );
    }
  },

  async updateTimeSheet(id, data) {
    try {
      set(
        produce((state) => {
          state.updatingTimeSheet = true;
          state.updatingTimeSheetError = {};
        })
      );

      const res = await axios({
        method: "PUT",
        url: `/administration/time-sheets/${id}`,
        data,
      });

      set(
        produce((state) => {
          state.updatedTimeSheet = res;
        })
      );
      get().getMyTimeSheets();
      get().setAddingTimeSheet();
    } catch (error) {
      set(
        produce((state) => {
          state.updatingTimeSheetError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.updatingTimeSheet = false;
        })
      );
    }
  },

  async deleteTimeSheet(id) {
    try {
      set(
        produce((state) => {
          state.deletingTimeSheet = true;
          state.deletingTimeSheetError = {};
        })
      );

      await axios({
        method: "DELETE",
        url: `/administration/time-sheets/${id}`,
      });

      set(
        produce((state) => {
          state.deletedTimeSheet = id;
        })
      );
      get().getMyTimeSheets();
    } catch (error) {
      set(
        produce((state) => {
          state.deletingTimeSheetError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.deletingTimeSheet = false;
        })
      );
    }
  },

  async deleteTimeSheetActivity(id, timeSheetId) {
    try {
      set(
        produce((state) => {
          state.deletingTimeSheetActivity = true;
          state.deletingTimeSheetActivityError = {};
          state.deletedTimeSheetActivity = null;
        })
      );

      await axios({
        method: "DELETE",
        url: `/administration/time-sheets/activities/${id}`,
      });

      set(
        produce((state) => {
          state.deletedTimeSheetActivity = id;
        })
      );
      get().getTimeSheetDetails(timeSheetId);
    } catch (error) {
      set(
        produce((state) => {
          state.deletingTimeSheetError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.deletingTimeSheetActivity = false;
        })
      );
    }
  },

  async approveTimeSheet(id) {
    try {
      set(
        produce((state) => {
          state.approvingTimeSheet = true;
          state.approvingTimeSheetError = {};
        })
      );

      const res = await axios({
        method: "PATCH",
        url: `/administration/time-sheets/approve/${id}`,
      });

      set(
        produce((state) => {
          state.approvedTimeSheet = res;
        })
      );
      get().getTimeSheetDetails(id);
    } catch (error) {
      set(
        produce((state) => {
          state.approvingTimeSheetError = error;
        })
      );
    } finally {
      set(
        produce((state) => {
          state.approvingTimeSheet = false;
        })
      );
    }
  },

  async submitTimeSheet(id) {
    try {
      set({ submittingTimeSheet: true, submitTimeSheetError: {}, submittedTimeSheet: null})

      const res = await axios({
        method: "PATCH",
        url: `/administration/time-sheets/submit/${id}`,
      });

     set({ submittedTimeSheet: res})
      get().getTimeSheetDetails(id);
    } catch (error) {
     set({ approvingTimeSheetError: error})
    } finally {
      set({ submittingTimeSheet: true, })
    }
  },

  setShowTimeSheetActivities(date = "", show = false) {
    set(
      produce((state) => {
        state.timeSheetActivitiesDate = date;
        state.showTimeSheetActivities = show;
      })
    );
  },

  setAddingTimeSheet(adding = false, showAdding = false, timeSheetToEdit = {}) {
    set(
      produce((state) => {
        state.addingTimeSheet = adding;
        state.showAddingTimeSheet = showAdding;
        state.timeSheetToEdit = timeSheetToEdit;
      })
    );
  },

  setAddingTimeSheetActivity(
    adding = false,
    show = false,
    timeSheetActivityToEdit = {}
  ) {
    set(
      produce((state) => {
        state.addingTimeSheetActivity = adding;
        state.showAddingTimeSheetActivity = show;
        state.timeSheetActivityToEdit = timeSheetActivityToEdit;
      })
    );
  },

  setShowViewTimeSheetDetails(show = false) {
    set(
      produce((state) => {
        state.showTimeSheetDetails = show;
        if (!show) {
          state.timeSheetDetails = {};
        }
      })
    );
  },

  async createTimeSheetActivity(data, timeSheetId) {
    set(
      produce((state) => {
        state.creatingTimeSheetActivity = true;
        state.createdTimeSheetActivity = null;
        state.creatingTimeSheetActivityError = {};
      })
    );
    try {
      await axios({
        method: "POST",
        url: "/administration/time-sheets/activities",
        data,
      });

      set(
        produce((state) => {
          state.createdTimeSheetActivity = data;
          state.creatingTimeSheetActivity = false;
        })
      );
      get().getTimeSheetDetails(timeSheetId);
    } catch (error) {
      set(
        produce((state) => {
          state.creatingTimeSheetActivityError = error;
          state.creatingTimeSheetActivity = false;
        })
      );
    }
  },

  async updateTimeSheetActivity(data, id, timeSheetId) {
    set(
      produce((state) => {
        state.updatingTimeSheetActivity = true;
        state.updatedTimeSheetActivity = null;
        state.updatingTimeSheetActivityError = {};
      })
    );
    try {
      await axios({
        method: "PUT",
        url: `/administration/time-sheets/activities/${id}`,
        data,
      });

      set(
        produce((state) => {
          state.updatedTimeSheetActivity = data;
          state.updatingTimeSheetActivity = false;
        })
      );
      get().setAddingTimeSheetActivity();
      get().getTimeSheetDetails(timeSheetId);
    } catch (error) {
      set(
        produce((state) => {
          state.updatingTimeSheetActivityError = error;
          state.updatingTimeSheetActivity = false;
        })
      );
    }
  },
});

const storeName = "TIME SHEETS STORE";

const useTimeSheetStore = create(devtools(store, { name: storeName }), {
  name: storeName,
});

export default useTimeSheetStore;
