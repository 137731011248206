import { Button, Popconfirm, Space } from "antd";

const tableButtonActions = (
  text,
  {
    viewDetails,
    updateRecord,
    confirmDelete,
    deleteRecord,
    deleteLoading,
    deleteId,
    extraActions,
  }
) => {
  const viewRecordDetails = () => {
    viewDetails({ target: { id: text.id } });
  };
  const updateRecordDetails = () => {
    updateRecord({ target: { id: text.id } });
  };
  const deleteRecordDetails = () => {
    deleteRecord({ target: { id: text.id } });
  };
  return (
    <Space direction="vertical">
      {extraActions}
      <Button className="w-100" onClick={viewRecordDetails} id={text?.id}>
        More
      </Button>
      <Button
        type="primary"
        className="w-100"
        onClick={updateRecordDetails}
        id={text?.id}
      >
        Edit
      </Button>
      <Popconfirm
        okText="Yes"
        cancelText="No"
        onConfirm={confirmDelete}
        title="Are you sure you want to delete this ?"
      >
        <Button
          type="primary"
          className="w-100"
          onClick={deleteRecordDetails}
          id={text?.id}
          danger
          loading={deleteLoading && deleteId === text?.id}
        >
          Delete{" "}
        </Button>
      </Popconfirm>
    </Space>
  );
};

export default tableButtonActions;
